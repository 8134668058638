import { toPng } from 'html-to-image';
import { jsPDF } from 'jspdf';
import LogRocket from 'logrocket';
import { TemplateData } from '../../TemplateData';

export const htmlToImageToPdf = async (
    templateId: number,
    templateData: TemplateData,
    companyName: string
  ) : Promise<jsPDF | null> => {
  
    LogRocket.track('Download PDF', {
      templateId,
      tsKundeNavn: companyName,
      fontColor: templateData.designData.fontColor,
      backgroundColor: templateData.designData.backgroundColor,
      fontFamily: templateData.designData.fontFamily,
      usedLogo: !!templateData.designData.logoFileName,
      usedBackgroundImage: !!templateData.designData.backgroundImageUrl,
      usedContactInfoName: !!templateData.contactInfo.name,
      usedContactInfoEmail: !!templateData.contactInfo.email,
      usedContactInfoPhone: !!templateData.contactInfo.phone,
    });
  
    const templateElement: HTMLElement | null = document.getElementById('template');
    if (!templateElement) {
      console.error('Template element not found');
      return null;
    }
  
    const scale = 3;
  
    try {
      const png = await toPng(templateElement, {
        canvasHeight: templateElement.offsetHeight * scale,
        canvasWidth: templateElement.offsetWidth * scale,
      });
  
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [templateElement.offsetWidth, templateElement.offsetHeight],
      });
  
      pdf.addImage(png, 'PNG', 0, 0, templateElement.offsetWidth, templateElement.offsetHeight, undefined, 'MEDIUM');        
      return pdf;
    } catch (error) {
      console.error('Error generating PDF:', error);
      return null;
    } 
  };