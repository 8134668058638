import Sidebar, { SubLink, NavLink } from ".";

const SidebarWrapper = () => {


	const navLinks: NavLink[] = [
		{ label: 'Home', link: '/landingpage', icon: 'home' },
		{ label: 'Designer', link: '/designer', icon: 'company' },		
	];

	return <Sidebar navLinks={navLinks} />;
};

export default SidebarWrapper;