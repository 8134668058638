

const NoAccess = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Du har ikke tilgang til Picasso</h1>      
      <img src="/img/NoAccess.jpeg" alt="No access" style={{ width: '100%', maxWidth: '500px', margin: '20px 0' }} />
      <p>Lyst på tilgang? Gå til <a href="https://tradesolution.no/tjenester/mybrandmedia-bildebank/">https://tradesolution.no/tjenester/mybrandmedia-bildebank/</a></p>

    </div>
  );
};
export default NoAccess;