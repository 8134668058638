import axios from "axios";

const baseUrl = "https://picassoapi.tradesolution.no/api";
//const baseUrl = "https://localhost:7125/api";

export interface ProductSearchResponse {
    items: ProductInfo[]
}

export interface ProductInfo {
    identificationNumber: string
    versions: Version[]
}

export interface Version {
    phase: string
    unitSmallPreviewImageGuids: string[]
    orderableUnitGtin: string
    supplierCompanyName: string
    variantName: string
    gtins: string[]
    basisGtins: string[]
}

export interface UploadedFileResponse {
    sasToken: string
    url: string
    urlWithToken: string
}

export interface LogoDto {
    fileName: string
}

export interface BackgroundImageDto {
    fileName: string
}

export interface CoopNnumberResponse {
    epdNummer: string
    coopNummer?: string 
    produktNavn?: string
}

export interface UploadPicassoStoreRequest {
    /**
     * A Title of the image
     */
    title: string;

    /**
     * Owner of the image
     */
    tsKundeId: string;
    
    /**
     * The file to upload
     */
    file: File;

    /**
     * The image can be connected to other GTINs.
     * Setting this will help the user to find the image when searching for a GTIN.
     */
    connectedGtins: string[];
  }

  export interface UploadPicassoResponse {
    /**
     * Indicates if the upload was successful
     */
    success: boolean;
  
    /**
     * Message related to the upload process
     */
    message: string;
  
    /**
     * URL for the user interface
     */
    userInterfaceUrl: string;
  }

export default {
    files: {
        post: async (file: File, tsKundeId: string): Promise<UploadedFileResponse> => {
            const formData = new FormData();
            formData.append("file", file);

            return (await axios.post(`${baseUrl}/Files?tsKundeId=${tsKundeId}`, formData)).data;
        },
        postBlob: async (file: Blob, tsKundeId: string): Promise<UploadedFileResponse> => {
            const formData = new FormData();
            formData.append("file", file);
            return (await axios.post(`${baseUrl}/Files?tsKundeId=${tsKundeId}`, formData)).data;
        }
    },
    productSearch: {
        get: async (query: string, tsKundeId: string, productOwnerGln: string): Promise<ProductSearchResponse> => {
            return (await axios.get(`${baseUrl}/ProductSearch?query=${query}&tsKundeId=${tsKundeId}&productOwnerGln=${productOwnerGln}`)).data;
        }
    },
    logos: {
        get: async (tsKundeId: string): Promise<LogoDto[]> => {
            return (await axios.get(`${baseUrl}/Logos?tsKundeId=${tsKundeId}`)).data;
        },
        getLogo: async (fileName: string, tsKundeId: string): Promise<Blob | null> => {
            try{
                const response = await axios.get(`${baseUrl}/Logos/${fileName}?tsKundeId=${tsKundeId}`, {
                    responseType: 'blob' // This allows handling binary data like images
                });
                return response.data;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    return null;
                }
                throw error;
            }
        },
        post: async (file: File, tsKundeId: string): Promise<LogoDto> => {
            const formData = new FormData();
            formData.append("file", file);
            return (await axios.post(`${baseUrl}/Logos?tsKundeId=${tsKundeId}`, formData)).data;
        },
        delete: async (fileName: string, tsKundeId: string) => {
            await axios.delete(`${baseUrl}/Logos/${fileName}?tsKundeId=${tsKundeId}`);
        },
    },
    bacgroundImages: {
        get: async (tsKundeId: string): Promise<BackgroundImageDto[]> => {
            return (await axios.get(`${baseUrl}/BackgroundImages?tsKundeId=${tsKundeId}`)).data;
        },
        getBackgroundIamge: async (fileName: string, tsKundeId: string): Promise<Blob | null> => {
            try{
                const response = await axios.get(`${baseUrl}/BackgroundImages/${fileName}?tsKundeId=${tsKundeId}`, {
                    responseType: 'blob' // This allows handling binary data like images
                });
                return response.data;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    return null;
                }
                throw error;
            }
        },
        getBackgroundIamgeAsThumbnail: async (fileName: string, tsKundeId: string): Promise<Blob | null> => {
            try{
                const response = await axios.get(`${baseUrl}/BackgroundImages/${fileName}/AsThumbnail?tsKundeId=${tsKundeId}`, {
                    responseType: 'blob' // This allows handling binary data like images
                });
                return response.data;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    return null;
                }
                throw error;
            }
        },
        post: async (file: File, tsKundeId: string): Promise<BackgroundImageDto> => {
            const formData = new FormData();
            formData.append("file", file);
            return (await axios.post(`${baseUrl}/BackgroundImages?tsKundeId=${tsKundeId}`, formData)).data;
        },
        delete: async (fileName: string, tsKundeId: string) => {
            await axios.delete(`${baseUrl}/BackgroundImages/${fileName}?tsKundeId=${tsKundeId}`);
        },
    },
    customDesignRequest: {
        post: async (email: string, name: string, phone: string | undefined, tsKundeNavn: string) => {
            await axios.post(`${baseUrl}/CustomDesign`, {
                email,
                name,
                phone,
                tsKundeNavn
            });
        }
    },
    coopNumber: {
        get: async (epdNr: string, tsKundeId: string): Promise<CoopNnumberResponse> => {
            return (await axios.get(`${baseUrl}/CoopNumbers/${epdNr}?tsKundeId=${tsKundeId}`)).data;
        }
    },
    upload: {
        post: async (request: UploadPicassoStoreRequest): Promise<UploadPicassoResponse> => {
            const formData = new FormData();
            formData.append("file", request.file);
            formData.append("title", request.title);
            formData.append("tsKundeId", request.tsKundeId);
            // Append each GTIN individually
            request.connectedGtins.forEach((gtin, index) => {
                formData.append(`connectedGtins[${index}]`, gtin.toString());
            });
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const response = await axios.post(`${baseUrl}/MyBrandUpload`, formData, config);
            return response.data;
        }
    }
}
