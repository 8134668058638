import { useEffect, useState } from "react";
import { generateBarcode } from "../../Shared/BarcodeGenerator";

interface Props {
    productData: ProductData;
}

export enum IdentifierType {
    GTIN = 'GTIN',
    COOP_NR = 'COOP NR',
    EPD_NR = 'EPD NR'
}

export interface ProductData {
    identiferType: IdentifierType
    products: ProductItem[]
}

export interface ProductItem {
    produktnavn: string
    epdNr: string
    basegtins: string[]
    barcodeInput?: string;
    identifierName?: string
    identifierValue?: string
    unitSmallPreviewImageGuid?: string | undefined
    strekkodeBase64Url?: string
    angle?: string | undefined
    orderableUnitGtin?: string | undefined
    coopNumber?: string | undefined
}

const generateBarcodeIfMissing = (product: ProductItem, identiferType: IdentifierType): ProductItem => {
    if (!product.strekkodeBase64Url) {
        switch (identiferType) {
            case IdentifierType.GTIN:
                product.strekkodeBase64Url = generateBarcode(product.orderableUnitGtin);
                break;
            case IdentifierType.COOP_NR:
                if (product.coopNumber !== 'notAvailable') {
                    product.strekkodeBase64Url = generateBarcode(product.coopNumber);
                }
                break;
            case IdentifierType.EPD_NR:
                // skip dummy product
                if (product.epdNr !== '-1') {
                    product.strekkodeBase64Url = generateBarcode(product.epdNr);
                }
                break;
        }
    }
    return product;
}

const getProductsWithBarcode = (productData: ProductData): ProductItem[] => {
    return productData.products.map(p => generateBarcodeIfMissing(p, productData.identiferType));
}

const ProductTable = (props: Props) => {

    const [products, setProducts] = useState<ProductItem[]>(getProductsWithBarcode(props.productData));

    useEffect(() => {
        setProducts(getProductsWithBarcode(props.productData));
    }, [props.productData]);
            
    const findIdentifierValue = (product: ProductItem, identiferType: IdentifierType) => {
        if (identiferType === IdentifierType.GTIN) {
            return product.orderableUnitGtin;
        }
        if (identiferType === IdentifierType.COOP_NR) {
            return product.coopNumber !== 'notAvailable' ? product.coopNumber : 'Har ikke Coop nr';
        }
        return product.epdNr;
    }

    const color = "#000";
    return (
        <table style={{ backgroundColor: '#fff', width: '100%', borderCollapse: 'collapse', fontSize: '13px', color: color, borderColor: color}}>
            <thead>
                <tr>
                    <th style={{ border: '1px solid', padding: '8px', textAlign: 'left'}}>Produktnavn</th>
                    <th style={{ border: '1px solid', padding: '8px', textAlign: 'center' }}>{props.productData.identiferType}</th>                    
                    <th style={{ border: '1px solid', padding: '8px', textAlign: 'center' }}>Strekkode ({props.productData.identiferType})</th>
                </tr>
            </thead>
            <tbody>
                {products.filter(x => x.epdNr !== '-1').map((row, index) => (
                    <tr key={index}>
                        <td style={{ border: '1px solid', padding: '8px', textAlign: 'left' }}>{row.produktnavn}</td>
                        <td style={{ border: '1px solid', padding: '8px', textAlign: 'center' }}>{findIdentifierValue(row, props.productData.identiferType)}</td>                        
                        <td style={{ border: '1px solid', padding: '2px', textAlign: 'center' }}>
                            {row.strekkodeBase64Url && <img src={row.strekkodeBase64Url} alt="barcode" />}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>);
}

export default ProductTable;