import { useEffect, useState } from "react";
import { useSelectedCompanyContext } from "../../../../Shared/SelectedCompanyContext";
import LogRocket from "logrocket";
import { IcebergButton, IconButton } from "@tradesolution/iceberg-ui-react";
import { Alert, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { UploadPicassoResponse, UploadPicassoStoreRequest } from "../../../../Services/PicassoApi";
import { SubmitHandler, useForm } from "react-hook-form";
import { TemplateData } from "../../TemplateData";
import { htmlToImageToPdf } from "../PdfService/pdfService";
import { MediastoreUser } from "../../../../Services/MediastoreMediaApi/models";
import MediastoreMediaApi from "../../../../Services/MediastoreMediaApi";
import PicassoApi from "../../../../Services/PicassoApi";
import { useNavigate } from "react-router-dom";

interface Props {    
	title: string;
  templateData: TemplateData;
  templateId: number; 
  }
  
  function MyBrandExportModal(props: Props) {
    const { register, handleSubmit, formState: { errors }, reset} = useForm<UploadPicassoStoreRequest>()	
    const { selectedCompany } = useSelectedCompanyContext();
    const [show, setShow] = useState(false);
    const handleClose = () => {setShow(false); setHasUploaded(false); setIsUploading(false);};
    const handleShow = () => setShow(true);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [hasUploaded, setHasUploaded] = useState<boolean>(false);
    const [mediastoreUser, setMediastoreUser] = useState<MediastoreUser>();
    const [hasMyBrandAccess, setHasMyBrandAccess] = useState<boolean>(true);
    const [gtins, setGtins] = useState<string[]>([]);
    const [response, setResponse] = useState<UploadPicassoResponse>();
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    
    const loadMediastoreUser = async () => { 
      try{  
        const response = await MediastoreMediaApi.mediastoreUser.get();
        setMediastoreUser(response);
        let hasAccess = response?.privateStoreSubscription?.isPaidSubscription ?? false;
        setHasMyBrandAccess(hasAccess);
      } 
      catch (error: any) {	
        if(error?.response && error.response?.status === 403){
          navigate('/unauthorized');
        }        			
      } 
    }

    useEffect(() => {
      loadMediastoreUser();
    }, []);
      
    const loadData = async () => {
      setResponse(undefined);
      let gtins = props.templateData.productData.products.filter(x => x.orderableUnitGtin).map(x => x.orderableUnitGtin!) ?? [];
      gtins.push(...props.templateData.productData.products.filter(x => x.basegtins.length > 0).map(x => x.basegtins).flat());
      setGtins(gtins);
      reset({
			title: props.title,
      tsKundeId: selectedCompany.tsCustomerId,
      connectedGtins: gtins,      
		  });
	};

    useEffect(() => {		
      loadData();
    }, [props.title, selectedCompany, props.templateData]);
    
    const onSubmit: SubmitHandler<UploadPicassoStoreRequest> = async data => {
      setIsUploading(true);
      const pdf = await htmlToImageToPdf(
        props.templateId,
        props.templateData,
        selectedCompany.name
      );
      if (!pdf) {
        setIsUploading(false);
        //Show error message
        return;
      }
      const blob = pdf.output('blob');
      const pdfFile = new File([blob], data.title + '.pdf', { type: 'application/pdf' });
      data.file = pdfFile;
      setErrorMessage('');
      try {
        let response = await PicassoApi.upload.post(data);
        setResponse(response);
        console.log(data);
        LogRocket.track('Export to MyBrand', {
            tsKundeNavn: selectedCompany.name,
            templateId: props.templateId,
            title: data.title,              
          });
        setIsUploading(false);
        setHasUploaded(true);
      }
      catch (error) {
        setErrorMessage('Noe gikk galt under opplastning');
        setIsUploading(false);
        return;
      }      
	  };
  
    return (
      <>        
        <IcebergButton variant="outline-primary" icon='export' onClick={handleShow}>
            Last opp til MyBrand
        </IcebergButton>
        <Modal show={show} onHide={handleClose} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>MyBrand opplastning</Modal.Title>            
          </Modal.Header>
          <Modal.Body>
            <Form>
              {!hasMyBrandAccess && (
                <Alert variant="warning">
                  <span>{selectedCompany.name} har ikke tilgang til MyBrand og du kan bare laste opp filer (ikke se). Lyst på <a href="https://tradesolution.no/tjenester/mybrandmedia-bildebank/" target="_blank">MyBrand Media?</a></span> 
                </Alert>
              )}              
              <p className="mb-3">Du laster opp til {selectedCompany.name} sitt Picasso arkiv i <a href="https://mediastore.tradesolution.no/gallery" target="_blank">Mediastore MyBrand Media</a></p>                           
              <Form.Group className="mb-3">
                  <Form.Label>Tittel</Form.Label>
                  <InputGroup>
                      <Form.Control                        
                          type="text" 
                          isInvalid={!!errors.title}
                          {...register("title", { required: "Tittel er påkrevd", maxLength: { value: 250, message:"Tittel kan max ha 250 tegn"}})} />
                          <Form.Control.Feedback type="invalid">
                          {errors.title && <span>{errors.title.message}</span>}
                      </Form.Control.Feedback>
                  </InputGroup>
              </Form.Group>
              {gtins.length > 0 && (
                <Form.Group className="mb-3">              
                  <p>Opplastningen kommer til å knyttes sammen med følgende GTIN slik at du kan bruke de i søk i MyBrand</p>
                  <Row className="mt-3">
                    <Col>
                      <h5>Bestilbar GTIN</h5>
                      <ul className="list-unstyled">
                        {props.templateData.productData.products.filter(x => x.orderableUnitGtin).map(x => <li key={x.orderableUnitGtin}>{x.orderableUnitGtin}</li>)}
                      </ul>
                    </Col>
                    <Col>
                      <h5>Basis GTIN</h5>
                      <ul className="list-unstyled">
                        {props.templateData.productData.products.filter(x => x.basegtins.length > 0).map(x => x.basegtins.map(y => <li key={y}>{y}</li>))}
                      </ul>      
                    </Col>
                  </Row>     
              </Form.Group>
              )}
              {hasUploaded && response && response.success && (
                < Alert variant="success">
                  <span>Fil lastet opp til <a href={response.userInterfaceUrl} target="_blank">{response.userInterfaceUrl}</a></span> 
                </Alert>
              )}
              {hasUploaded && response && response.success && !hasMyBrandAccess && (
                < Alert variant="success">
                  <span>Fil lastet opp</span> 
                </Alert>
              )}
              {errorMessage && (
              <Alert variant="danger">
                {errorMessage}
              </Alert>
            )}                          
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <>
                <IcebergButton variant="outline-primary" icon="close" className="col" onClick={handleClose}>
                    Avbryt
                </IcebergButton>
                {!hasUploaded && (
                  <IcebergButton isLoading={isUploading} variant="primary" icon="export" className="col" onClick={handleSubmit(onSubmit)}>
                    Last opp
                  </IcebergButton>
                )}
                {hasUploaded && (
                  <IcebergButton variant="primary" icon="close" className="col" onClick={handleClose}>
                    Lukk
                  </IcebergButton>
                )}                
            </>
            </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default MyBrandExportModal;
