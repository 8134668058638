import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Designer from './pages/Designer';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './Shared/Auth/authConfig';
import BarcodeCreator from './pages/BarcodeCreator';
import AdvancedColorPicker from './pages/Designer/Components/AdvancedColorPicker';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { SelectedCompanyProvider } from './Shared/SelectedCompanyContext';
import LandingPage from './pages/LandingPage';
import NoAccess from './pages/NoAccess';

const msalInstance = new PublicClientApplication(msalConfig);
LogRocket.init('msbcwv/picasso-5f9nd');
setupLogRocketReact(LogRocket);
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<MsalProvider instance={msalInstance}>
		<SelectedCompanyProvider>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<App />}>
						<Route index element={<LandingPage />} />
						<Route path='/designer' element={<Designer />} />						
						<Route path='/landingpage' element={<LandingPage />} />						
					</Route>
					<Route path='/unauthorized' element={<NoAccess/>} />
				</Routes>
			</BrowserRouter>
		</SelectedCompanyProvider>
	</MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
