import JsBarcode from "jsbarcode";

export const generateBarcode = (inputValue?: string): string => {
    if(!inputValue) {
        return '';
    }    
    const canvas = document.createElement('canvas');

    // Generate the barcode using JsBarcode
    JsBarcode(canvas, inputValue, {
        format: "CODE39",
        lineColor: "#000",
        background: undefined,
        width: 1,
        height: 30,
        displayValue: false,
    });

    return canvas.toDataURL('image/png');

    // Convert the SVG element to a Base64 data URL
    // const svgData = new XMLSerializer().serializeToString(svg);
    // const base64Data = btoa(svgData);
    // return `data:image/svg+xml;base64,${base64Data}`;
}