import { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownButton, Form, Row, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PicassoApi, { ProductInfo } from '../../../../Services/PicassoApi';
import { IcebergButton } from '@tradesolution/iceberg-ui-react';
import { IdentifierType, ProductData, ProductItem } from '../../../../components/ProductTable';
import useDebounce from '../../../../Shared/useDebounce';
import { useSelectedCompanyContext } from '../../../../Shared/SelectedCompanyContext';

interface Props {
	selectedProducts?: ProductItem[];
	onSave: (productData: ProductData) => void;
}

function ProduktModal(props: Props) {

	const { selectedCompany } = useSelectedCompanyContext();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [q, setQ] = useState('');
	const debouncedQ = useDebounce(q, 250);
	const [products, setProducts] = useState<ProductInfo[]>([]);
	const [barcodeInput, setBarcodeInput] = useState<IdentifierType>(localStorage.getItem('barcodeInput') as IdentifierType ?? IdentifierType.EPD_NR);
	// flytt valgte produkter hit
	const [selectedProducts, setSelectedProducts] = useState<ProductItem[]>(props.selectedProducts ?? []);
	const [gettingCoopNumber, setGettingCoopNumber] = useState(false);
	const [gettingCoopNumberForProduct, setGettingCoopNumberForProduct] = useState<String | undefined>();

	useEffect(() => {
		setSelectedProducts(props.selectedProducts ?? []);
	}, [props.selectedProducts]);

	useEffect(() => {
		const updatedProducts = selectedProducts.map(product => ({
			...product,
			barcodeInput: barcodeInput,
			strekkodeBase64Url: ''
		}));
		setSelectedProducts(updatedProducts);
	}, [barcodeInput]);

	const updateProductsWithCoopNumber = async (products: ProductItem[]) => {
		setGettingCoopNumber(true);
		const updatedProducts = await Promise.all(products.map(async product => {
			if (!product.coopNumber) {
				product.coopNumber = await getCoopNumber(product.epdNr);
				product.barcodeInput = barcodeInput;
				product.strekkodeBase64Url = '';
			}
			return product;
		}));
		setSelectedProducts(updatedProducts);
		setGettingCoopNumber(false);
	};

	useEffect(() => {
		if (barcodeInput === 'COOP NR') {
			const productsMissingCoopNumber = selectedProducts.filter(p => !p.coopNumber);
			if (productsMissingCoopNumber.length === 0) {
				return;
			}
			const temp = [...selectedProducts];
			updateProductsWithCoopNumber(temp);
		}
	}, [barcodeInput]);

	const queryProducts = async (query: string) => {
		const productSearchResponse = await PicassoApi.productSearch.get(query, selectedCompany?.tsCustomerId, selectedCompany?.gln?.toString());
		setProducts(productSearchResponse.items ?? []);
	};

	useEffect(() => {
		if (selectedCompany?.tsCustomerId) {
			queryProducts(debouncedQ);
		}
	}, [debouncedQ, selectedCompany]);

	const handleSavedProducts = () => {
		console.log('barcodeInput', barcodeInput);
		props.onSave({
			identiferType: barcodeInput,
			products: selectedProducts,
		});
		setShow(false);
		setQ('');
	}

	const mapToProductData = (productInfo: ProductInfo): ProductItem => {
		let product = {
			produktnavn: productInfo.versions[0].variantName,
			epdNr: productInfo.identificationNumber,
			basegtins: productInfo.versions[0].basisGtins ?? productInfo.versions[0].gtins,
			unitSmallPreviewImageGuid: productInfo.versions[0].unitSmallPreviewImageGuids.length > 0 ? productInfo.versions[0].unitSmallPreviewImageGuids[0] : undefined,
			angle: undefined,
			orderableUnitGtin: productInfo.versions[0].orderableUnitGtin,
			barcodeInput: barcodeInput,
			strekkodeBase64Url: '',
		};
		return product;
	}

	const updateProductWithCoopNumber = async (product: ProductItem) => {
		setGettingCoopNumberForProduct(product.epdNr);		
		if (!product.coopNumber) {
			product.coopNumber = await getCoopNumber(product.epdNr);
		}
		setGettingCoopNumberForProduct('');
		return product;	
	};

	const getCoopNumber = async (epdNr: string) => {
		if (barcodeInput !== 'COOP NR') return undefined;
		const response = await PicassoApi.coopNumber.get(epdNr, selectedCompany.tsCustomerId);
		return response?.coopNummer ?? "notAvailable";
	};

	//Funksjon som hånterer valgte produkter
	const selectProduct = async (productInfo: ProductInfo) => {
		if (selectedProducts.map(o => o.epdNr).includes(productInfo.identificationNumber)) {
			setSelectedProducts(selectedProducts.filter(p => p.epdNr !== productInfo.identificationNumber));
		} else {
			let updated = await updateProductWithCoopNumber(mapToProductData(productInfo));
			setSelectedProducts([...selectedProducts, updated]);
		}
	}

	const removeSelectedProduct = (product: ProductItem) => {
		setSelectedProducts(selectedProducts.filter(p => p.epdNr !== product.epdNr));
	}

	//Barcode input
	const barcodeInputList = Object.values(IdentifierType);;

	// Handler for setting barcode input
	const handleBarcodeInputChange = (input: string) => {
		console.log('input', input);

		setBarcodeInput(input as IdentifierType);
		localStorage.setItem('barcodeInput', input);
	};

	const findGtinOrCoopNrText = (product: ProductItem) => {
		if (barcodeInput === 'GTIN' || barcodeInput === 'EPD NR')
			return product.orderableUnitGtin;
		if (!product.coopNumber && gettingCoopNumber)
			return 'Henter data...';
		if (product.coopNumber && product.coopNumber !== 'notAvailable')
			return product.coopNumber;
		else return 'Har ikke Coop nr';
	};

	return (
		<>
			<div className="d-grid">
				<IcebergButton variant="outline-primary" icon='plus' onClick={handleShow}>
					Legg til produkt
				</IcebergButton>
			</div>

			<Modal show={show} onHide={handleClose} size='xl'>
				<Modal.Header closeButton>
					<Modal.Title>Velg produkter for {selectedCompany.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/*Valgte produkter */}
					<div className="mb-4">
						<Row>
							<Col>
								<h5>Valgte Produkter ({selectedProducts.length})</h5>
							</Col>
							<Col>
								<Row>
									<Col className='text-end' xs={12} md={8}>
										<h5>Velg hva strekkode lages av: </h5>
									</Col>
									<Col className='text-end'>
										<DropdownButton
											id="barcode-input-dropdown"
											title={barcodeInput}
											onSelect={(input) => handleBarcodeInputChange(input || 'EPD NR')}
											variant="outline-primary"
											defaultValue={barcodeInputList[0]}
										>
											{barcodeInputList.map((input, index) => (
												<Dropdown.Item key={index} eventKey={input}>{input}</Dropdown.Item>
											))}
										</DropdownButton>
									</Col>
								</Row>
							</Col>
						</Row>

						{selectedProducts.length > 0 ? (
							<Table>
								<thead>
									<tr>
										<th className='text-center'>Bilde</th>
										<th>Produktnavn</th>
										<th>EPD Nr</th>
										<th>{barcodeInput === 'COOP NR' ? barcodeInput : 'GTIN'}</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{selectedProducts.map((product, index) => (
										<tr key={`selected-${index}`}>
											<td className='text-center'>
												{product.unitSmallPreviewImageGuid && <img height={30} src={`https://mediastore.tradesolution.no/download/preview/${product.unitSmallPreviewImageGuid}`} alt={product.produktnavn} />}
											</td>
											<td>{product.produktnavn}</td>
											<td>{product.epdNr}</td>
											<td>{findGtinOrCoopNrText(product)}</td>
											<td className='text-end'>
												<IcebergButton variant='outline-secondary' icon='close' onClick={() => removeSelectedProduct(product)}>
													Fjern
												</IcebergButton>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						) : (
							<p>Ingen produkter er valgt</p>
						)}
					</div>


					<div className="row mb-4">
						<div className="col-6">
							<Button variant="outline-secondary" className="w-100" onClick={handleClose}>
								Close
							</Button>
						</div>
						<div className="col-6">
							<Button variant="primary" className="w-100" onClick={handleSavedProducts}>
								Lagre
							</Button>
						</div>
					</div>

					{/*Søk etter produkter */}
					<Form.Group>
						<Form.Label>Søk</Form.Label>
						<Form.Control type="search" placeholder="Søk EPD Nr eller produktnavn"
							value={q}
							onChange={e => setQ(e.target.value)}
						/>
					</Form.Group>
					<Table>
						<thead>
							<tr>
								<th className='text-center'>Bilde</th>
								<th>Produktnavn</th>
								<th>EPD Nr</th>
								<th>GTIN</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{products.map((product, index) => (
								<tr key={product.identificationNumber}>
									<td className='text-center'>
										{product.versions[0].unitSmallPreviewImageGuids[0] && <img height={30} src={`https://mediastore.tradesolution.no/download/preview/${product.versions[0].unitSmallPreviewImageGuids[0]}`} alt={product.versions[0].variantName} />}
									</td>
									<td>{product.versions[0].variantName}</td>
									<td>{product.identificationNumber}</td>
									<td>{product.versions[0].orderableUnitGtin}</td>
									<td className='text-end'>
										<IcebergButton
											isLoading={gettingCoopNumberForProduct === product.identificationNumber}
											disabled={gettingCoopNumberForProduct && gettingCoopNumberForProduct !== product.identificationNumber}
											variant={selectedProducts.map(o => o.epdNr).includes(product.identificationNumber) ? 'outline-secondary' : 'outline-primary'}
											icon={selectedProducts.map(o => o.epdNr).includes(product.identificationNumber) ? 'check' : 'plus'}
											onClick={() => selectProduct(product)}
										>
											{selectedProducts.map(o => o.epdNr).includes(product.identificationNumber) ? 'Valgt' : 'Velg'}
										</IcebergButton>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
		</>
	);
}


export default ProduktModal;