import { IcebergButton, IcebergIcon } from "@tradesolution/iceberg-ui-react";
import React, { useState } from "react";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import ProduktModal from "../ProduktModal";
import { ProductData, ProductItem } from "../../../../components/ProductTable";
import ProductImageSelector from "./ProductImageSelector";

interface Props {
	onTemplateSelected: (o: Template) => void;
	onProductDataChanged: (data: ProductData) => void;
	onAngleSelected: (gtin: string, angle: string) => void;
	productData: ProductData;
}

export interface Template {
	name: string;
	id: number;
	imageCount: number;
	maxProductCount: number;
	thumbnailUrl?: string;
}

export const Templates: Template[] = [
	{ name: 'Template 1', id: 1, imageCount: 1, maxProductCount: 4, thumbnailUrl: "/img/template1.png" },
	{ name: 'Template 2', id: 2, imageCount: 3, maxProductCount: 4, thumbnailUrl: "/img/template2.png" },
	{ name: 'Template 3', id: 3, imageCount: 4, maxProductCount: 4, thumbnailUrl: "/img/template3.png" },
	{ name: 'Template 4', id: 4, imageCount: 2, maxProductCount: 4, thumbnailUrl: "/img/template4.png" },
	{ name: 'Template 5', id: 5, imageCount: 2, maxProductCount: 4, thumbnailUrl: "/img/template5.png" },
	{ name: 'Template 6', id: 6, imageCount: 2, maxProductCount: 4, thumbnailUrl: "/img/template6.png" },
	{ name: 'Template 7', id: 7, imageCount: 3, maxProductCount: 4, thumbnailUrl: "/img/template7.png" },
	{ name: 'Template 8', id: 8, imageCount: 3, maxProductCount: 4, thumbnailUrl: "/img/template8.png" },
	{ name: 'Template 9', id: 9, imageCount: 3, maxProductCount: 4, thumbnailUrl: "/img/template9.png" },
	{ name: 'Template 10', id: 10, imageCount: 4, maxProductCount: 4, thumbnailUrl: "/img/template10.png" },
	{ name: 'Template 11', id: 11, imageCount: 4, maxProductCount: 4, thumbnailUrl: "/img/template11.png" },
	{ name: 'Template 12', id: 12, imageCount: 0, maxProductCount: 4, thumbnailUrl: "/img/template12.png" },
];

const LeftCol = (props: Props) => {

	const [selectedTemplate, setSelectedTemplate] = useState<Template>(Templates.find(o => o.id === 1) as Template);

	//Drag and drop for table content
	const [draggingIndex, setDraggingIndex] = useState<number | null>(null); // Tracks the row being dragged
	const [offsetY, setOffsetY] = useState(0); // Track Y-offset for precise positioning
	const [hoverIndex, setHoverIndex] = useState<number | null>(null); // Track the row being hovered over

	const onDragStart = (event: React.DragEvent, index: number) => {
		const rect = (event.target as HTMLElement).getBoundingClientRect();
		setOffsetY(event.clientY - rect.top);
		setDraggingIndex(index); // Set the currently dragged index
	};

	const onDragOver = (event: React.DragEvent, index: number) => {
		event.preventDefault(); // Allow drop by preventing the default action
		setHoverIndex(index); // Track the row being hovered over
	};

	const onDrop = (event: React.DragEvent, dropIndex: number) => {
		event.preventDefault();
		if (draggingIndex !== null) {
			const updatedProductData = { ...props.productData };
			const [draggedRow] = updatedProductData.products.splice(draggingIndex, 1); // Remove the dragged row
			updatedProductData.products.splice(dropIndex, 0, draggedRow); // Insert it at the new position

			props.onProductDataChanged(updatedProductData); // Update the parent component's state
			setDraggingIndex(null); // Clear dragging state
			setHoverIndex(null); // Clear hover state
		}
	};

	const handleTemplateSelected = (o: Template): void => {
		setSelectedTemplate(o);
		props.onTemplateSelected(o);
		const products = addPlaceholderProductsTo(props.productData.products, o);
		props.onProductDataChanged({ ...props.productData, products });
	}

	const handleProductsSaved = async (productData: ProductData): Promise<void> => {
		const products = addPlaceholderProductsTo(productData.products, selectedTemplate);
		props.onProductDataChanged({ ...productData, products });
	}

	const removeProduct = (row: ProductItem): void => {
		let remainingProducts = props.productData.products.filter((product) => product !== row);
		remainingProducts = addPlaceholderProductsTo(remainingProducts, selectedTemplate);
		props.onProductDataChanged({ ...props.productData, products: remainingProducts });
	}

	const handleAngleSelected = (gtin: string, angle: string): void => {
		props.onAngleSelected(gtin, angle);
	}

	const addPlaceholderProductsTo = (products: ProductItem[], template: Template): ProductItem[] => {
		const productsWithoutPlaceholder = products.filter(x => x.epdNr !== '-1');
		var count = template.imageCount - productsWithoutPlaceholder.length;
		if (count <= 0) {
			return productsWithoutPlaceholder;
		}

		if (productsWithoutPlaceholder.length === 0) {
			return productsWithoutPlaceholder;
		}

		const newProducts = [...productsWithoutPlaceholder];
		for (let i = 0; i < count; i++) {
			newProducts.push({
				produktnavn: 'Ikke valgt',
				epdNr: `-1`,
				basegtins: [],
				angle: undefined,
				unitSmallPreviewImageGuid: undefined
			});
		}
		return newProducts;
	}

	return (
		<div>
			<Accordion defaultActiveKey="0">
				<Accordion.Item eventKey="0">
					<Accordion.Header><IcebergIcon icon="document" className='me-2' /> Velg mal</Accordion.Header>
					<Accordion.Body>
						<div>
							<div className="row row-cols-3 g-3">
								{Templates.map((o) => (
									<div className="col" key={o.id}>
										<div
											className={`p-2 border ${selectedTemplate?.id === o.id ? "border-primary border-3" : "border-secondary"}`}
											style={{ cursor: "pointer", borderRadius: "5px" }}
											onClick={() => handleTemplateSelected(o)}
										>
											<img
												src={o.thumbnailUrl}
												alt={`Template ${o.id}`}
												className="img-fluid"
												style={{ width: "100%", height: "auto" }}
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			{/*Table Content*/}
			<Accordion defaultActiveKey="0" className="mt-3">
				<Accordion.Item eventKey="0">
					<Accordion.Header>
						<IcebergIcon icon="cards" className="me-2" /> Produkter
					</Accordion.Header>
					<Accordion.Body>
						{props.productData.products.length > 0 && <table className='table'>
							<thead>
								<tr>
									<th />
									<th />
									<th className="text-end">
										{props.productData.products.length > 0 && (
											<OverlayTrigger
												placement="right"
												overlay={
													<Tooltip id="drag-drop-tooltip">
														Du kan dra og slippe rader her for å endre rekkefølgen.
													</Tooltip>
												}
											>
												<span style={{ marginLeft: "8px", cursor: "help", float: "right" }}>
													<IcebergIcon icon="info" />
												</span>
											</OverlayTrigger>
										)}
									</th>
								</tr>
							</thead>
							<tbody>
								{props.productData.products.map((row, index) => (
									<tr
										key={index}
										draggable
										onDragStart={(event) => onDragStart(event, index)}
										onDragOver={(event) => onDragOver(event, index)}
										onDrop={(event) => onDrop(event, index)}
										style={{
											cursor: "move",
											border: draggingIndex === index ? "2px solid red" : "none", //a red border for the dragged row
											backgroundColor: hoverIndex === index ? "#f9f9f9" : "white", // Highlight row on hover
										}}
									>
										<td>
											<span style={{ cursor: "move" }}>
												<IcebergIcon size="sm" icon="menu"></IcebergIcon>
											</span>
										</td>
										<td>{row.produktnavn}</td>
										<td className="text-end">
											{row.epdNr !== '-1' && (
												<IcebergButton
													size="sm"
													variant="outline-danger"
													icon="trash"
													onClick={() => removeProduct(row)}
												/>
											)}
										</td>
									</tr>
								))}
							</tbody>

						</table>}
						{/*Modal */}
						<ProduktModal onSave={handleProductsSaved} selectedProducts={props.productData.products.filter(x => x.epdNr !== '-1')} />
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<ProductImageSelector gtins={props.productData.products.filter(x => x.epdNr !== '-1').map(o => o.basegtins[0])} template={selectedTemplate} onAngleSelected={handleAngleSelected} />
		</div>
	)
}

export default LeftCol;